@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PPMori;
  src: url("../public/PPMori-Regular.otf");
}
body {
  font-family: PPMori, sans-serif;
  background-color: #040a20;
}
p {
  color: #7787a6;
}

.bg-cstm {
  background-image: url("../public/Mask\ group.png");
}

@media (min-height: 820px) {
  .cstm-div {
    height: 100vh;
  }
}

.cstm-brdr {
  border-left-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #79c059, #2ac0da, #da9e89) 1;
}

.cstm-btn {
  background-image: linear-gradient(172deg, #77AAFF, #2776F9);
}

.cstm-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/* background-color: #F5F5F5; */
}

.cstm-scroll::-webkit-scrollbar
{
	width: 8px;
	/* background-color: #F5F5F5; */
}

.cstm-scroll::-webkit-scrollbar-thumb
{
	background-color: #2777f95e;
	/* border: px solid #555555; */
}

@media (min-width: 1250px) {
  .cstm-mrgn {
      margin-left: -25%;
  }
}












